import React from 'react';

function Skill(props) {

    return (
        <div data-aos="fade-top" className="skill" data-aos-duration="300">
            <div className="img_skill">
                <img src={props.imageUrl} alt="Logo skill" />
            </div>
            <span className="name_skill">{props.name}</span>
        </div>
    )
}

export default Skill;
import Experience from "../components/Experience";
import React, { useEffect } from "react";
import Aos from "aos";

function Experiences() {

    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);

    return (
        <div id="experiences" className="timeline-section" data-aos="fade-up" data-aos-duration="300">
            <h2 className="title">Parcours</h2>
            <div className="timeline-items">

                <Experience
                    title="CDD Développeur FullStack chez ID-Ingénierie"
                    date="Septembre 2023 à Octobre 2023"
                    description="Mise en place de nouvelles fonctionnalités sur leur application
                    phare (Prod-up)"
                />

                <Experience
                    title="Stage Développeur FullStack chez ID-Ingénierie"
                    date="Avril 2023 à Août 2023"
                    description="Stage de validation de ma licence professionnelle, j'avais pour mission de refactor une application de reporting sur le terrain."
                />

                <Experience
                    title="LP Développement Internet et Mobile"
                    date="Septembre 2022 à Août 2023"
                    description="IUT Calais"
                />

                <Experience
                    title="Stage Développeur WEB chez ID-Ingénierie"
                    date="Avril 2022 à Juin 2022 | 10 semaines"
                    description="Stage de validation de DUT, réalisé chez l'entreprise ID-Ingenierie à Gravelines. La grande partie de ce stage à été réalisée en télétravail"
                />

                <Experience
                    title="DUT Informatique"
                    date="Septembre 2020 à Août 2022"
                    description="IUT Calais"
                />

                <Experience
                    title="Baccalauréat STI2D"
                    date="Septembre 2018 à Août 2020"
                    description="Lycée de l'Europe Dunkerque"
                />
            </div>
        </div>
    )
}

export default Experiences;

import React from "react";
import Navbar from "../components/Navbar";

export default function Accueil() {

    const adresseEmail = 'louis.dedrie@mail.com';

    return <div id="accueil" className="accueil">
        <div className="blob">
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path fill="#0D2847" transform="translate(100 100)">
                    <animate attributeName="d"
                        dur="20000ms"
                        repeatCount="indefinite"
                        values="
                        M37,-50.3C48.8,-42.2,60.1,-32.7,69.3,-19C78.6,-5.4,85.8,12.5,79.7,24.2C73.5,35.9,53.8,41.4,38.3,48C22.8,54.6,11.4,62.1,-1.8,64.5C-15,67,-30,64.5,-39.5,56C-49,47.5,-53,33,-53.7,20C-54.4,7,-51.8,-4.5,-48.6,-16.2C-45.3,-27.9,-41.4,-39.8,-33.2,-49.1C-25,-58.4,-12.5,-65,0,-65C12.5,-65,25.1,-58.4,37,-50.3Z; 
                        M36.1,-47.6C51.1,-38.9,70.3,-34,79,-22.1C87.8,-10.2,86.2,8.6,77.5,22C68.8,35.5,53.1,43.5,39,49C24.9,54.6,12.5,57.6,-1.1,59.2C-14.7,60.7,-29.5,60.8,-40.7,54.3C-51.8,47.9,-59.4,34.8,-64.6,20.6C-69.9,6.3,-72.8,-9.1,-70.8,-25.3C-68.7,-41.5,-61.7,-58.4,-49.1,-67.9C-36.5,-77.3,-18.3,-79.2,-3.8,-73.9C10.6,-68.7,21.2,-56.2,36.1,-47.6Z;
                        M46.6,-66.2C54.8,-58.3,51.7,-37.1,51.9,-20.6C52.1,-4.1,55.6,7.5,55,20.5C54.4,33.5,49.7,47.7,39.9,61C30,74.3,15,86.6,-0.8,87.7C-16.6,88.8,-33.2,78.6,-42.9,65.3C-52.6,52,-55.5,35.5,-55.9,21.2C-56.3,7,-54.2,-4.9,-49.7,-15.4C-45.3,-25.9,-38.5,-34.8,-29.9,-42.5C-21.2,-50.2,-10.6,-56.6,4.3,-62.6C19.3,-68.6,38.5,-74.1,46.6,-66.2Z;
                        M39.7,-50.4C54,-44.3,69.9,-36.1,78.4,-22.5C87,-8.9,88.3,10.1,82.9,27C77.5,43.9,65.4,58.6,50.5,64.2C35.5,69.8,17.8,66.2,2.1,63.2C-13.5,60.3,-27,58,-41.1,52.2C-55.2,46.3,-69.8,36.9,-73.8,24.1C-77.8,11.3,-71.2,-4.8,-65.8,-21.3C-60.4,-37.8,-56.2,-54.7,-45.4,-62C-34.7,-69.2,-17.3,-66.9,-2.3,-63.7C12.7,-60.5,25.5,-56.5,39.7,-50.4Z;
                        M37,-50.3C48.8,-42.2,60.1,-32.7,69.3,-19C78.6,-5.4,85.8,12.5,79.7,24.2C73.5,35.9,53.8,41.4,38.3,48C22.8,54.6,11.4,62.1,-1.8,64.5C-15,67,-30,64.5,-39.5,56C-49,47.5,-53,33,-53.7,20C-54.4,7,-51.8,-4.5,-48.6,-16.2C-45.3,-27.9,-41.4,-39.8,-33.2,-49.1C-25,-58.4,-12.5,-65,0,-65C12.5,-65,25.1,-58.4,37,-50.3Z
                        "
                    ></animate>
                </path>
            </svg>
        </div>
        <Navbar className="navbar" />
        <div className="aboutMe">
            <div className="gauche" data-aos="fade-right" data-aos-duration="800">
                <div className="info1">
                    <h1>Louis Dedrie</h1>
                    <p><span>Développeur FullStack</span> passionné par le développement web. Toujours avide d'apprendre et de relever de nouveaux défis.</p>
                </div>

                <div className="socialMediaButton">
                    <a href="https://github.com/Pikaadaa" className="div">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 24 24"><title>github</title><path d="M12,2A10,10 0 0,0 2,12C2,16.42 4.87,20.17 8.84,21.5C9.34,21.58 9.5,21.27 9.5,21C9.5,20.77 9.5,20.14 9.5,19.31C6.73,19.91 6.14,17.97 6.14,17.97C5.68,16.81 5.03,16.5 5.03,16.5C4.12,15.88 5.1,15.9 5.1,15.9C6.1,15.97 6.63,16.93 6.63,16.93C7.5,18.45 8.97,18 9.54,17.76C9.63,17.11 9.89,16.67 10.17,16.42C7.95,16.17 5.62,15.31 5.62,11.5C5.62,10.39 6,9.5 6.65,8.79C6.55,8.54 6.2,7.5 6.75,6.15C6.75,6.15 7.59,5.88 9.5,7.17C10.29,6.95 11.15,6.84 12,6.84C12.85,6.84 13.71,6.95 14.5,7.17C16.41,5.88 17.25,6.15 17.25,6.15C17.8,7.5 17.45,8.54 17.35,8.79C18,9.5 18.38,10.39 18.38,11.5C18.38,15.32 16.04,16.16 13.81,16.41C14.17,16.72 14.5,17.33 14.5,18.26C14.5,19.6 14.5,20.68 14.5,21C14.5,21.27 14.66,21.59 15.17,21.5C19.14,20.16 22,16.42 22,12A10,10 0 0,0 12,2Z" /></svg>
                        <span>Github</span>
                    </a>

                    <a href="https://www.linkedin.com/in/louis-dedrie-9808b6223/" className="div">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 24 24"><title>linkedin</title><path d="M19 3A2 2 0 0 1 21 5V19A2 2 0 0 1 19 21H5A2 2 0 0 1 3 19V5A2 2 0 0 1 5 3H19M18.5 18.5V13.2A3.26 3.26 0 0 0 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17A1.4 1.4 0 0 1 15.71 13.57V18.5H18.5M6.88 8.56A1.68 1.68 0 0 0 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19A1.69 1.69 0 0 0 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56M8.27 18.5V10.13H5.5V18.5H8.27Z" /></svg>
                        <span>Linkedin</span>
                    </a>

                    <a href={`mailto:${adresseEmail}`} className="div">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 24 24"><title>email</title><path d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z" /></svg>
                        <span>louis.dedrie@gmail.com</span>
                    </a>

                    <a href="CV DEDRIE_Louis 1 (4).pdf" download="CV DEDRIE_Louis 1 (4).pdf" className="div">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 24 24"><title>download cv</title><path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" /></svg>
                        <span>Mon CV</span>
                    </a>
                </div>

            </div>
            <div className="droite" data-aos="fade-right" data-aos-duration="800">
                <img src="./img/Design sans titre (1) (1).png" alt="Louis" />
            </div>
        </div>

    </div>;

}
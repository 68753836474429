import React from 'react';

function Experience(props) {

    return (
        <div data-aos="fade-up" className="timeline-item" data-aos-duration="300">
            <div className="timeline-dot"></div>
            <div className="timeline-date">{props.date}</div>
            <div className="timeline-content">
                <h3>{props.title}</h3>
                <p>{props.description}</p>
            </div>
        </div>
    )
}

export default Experience;
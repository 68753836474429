import React from 'react';

function CardProjet(props) {

    const words = props.technologies.split(',');
    const tagsImages = props.tagsImages.split(',');

    return (
        <div className="projet" data-aos="fade-up" data-aos-duration="300">
            <h4>{props.title}</h4>
            <p className="description">{props.description}</p>
            <p className="context">{props.contexte}</p>
            <div className="tags">
                {words.map((tag, index) => (
                    <div key={index} className="tag">
                        <img src={tagsImages[index]} alt="Logo technologie" />
                        {tag}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CardProjet;
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Accueil from './pages/Accueil';
import Experiences from './pages/Experiences';
import Projets from './pages/Projets';
import Hobbies from './pages/Hobbies';
import Competences from './pages/Competences';
import 'react-slideshow-image/dist/styles.css'
import "aos/dist/aos.css";
import Contact from './pages/Contact';

function App() {

  return (
    <BrowserRouter>
      <div className="App">
        <div className="divFlou" id="divFlou"></div>
        <Accueil />
        <Competences />
        <Projets />
        <Experiences />
        <Hobbies />
      </div>
    </BrowserRouter>
  );
}

export default App;

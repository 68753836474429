import CardProjet from "../components/CardProjet";
import React from "react";

function Projets() {


    return (
        <div id="projets" className="section_projets" data-aos="fade-up" data-aos-duration="300">
            <h2 className="title">Projets</h2>
            <div className="projets">
                <CardProjet
                    title="Capturs"
                    description="Projet professionnel"
                    technologies="Laravel, VueJS, Mysql"
                    screenshot="./img/géolocalisation.png"
                    contexte="Application web de géolocalisation réalisé en stage chez l'entreprise ID-Ingenierie. Le but de ce projet était de réalisé une application web qui géolocalisait des équipements grâce à des capteurs GPS."
                    images="./img/Capturs1.png,./img/Capturs2.png,./img/Capturs3.png,./img/Capturs4.png"
                    lien=""
                    github="https://github.com/Pikaadaa/Projet-Capteur"
                    tagsImages="./img/pngwing.com (1).png, ./img/pngwing.com (4).png, ./img/pngwing.com (2).png"
                />

                <CardProjet
                    title="Pilot-Up"
                    description="Projet professionnel"
                    technologies="Laravel, VueJS, Mysql"
                    screenshot=""
                    contexte="Application web de réporting réalisé chez l'entreprise ID-Ingenierie. Ce projet est une application web permettant aux utilisateurs de créer leurs rapports d'intervention directement sur le terrain et de l'envoyer au client."
                    images=""
                    lien=""
                    github=""
                    tagsImages="./img/pngwing.com (1).png, ./img/pngwing.com (4).png, ./img/pngwing.com (2).png"
                />

                <CardProjet
                    title="Intégration"
                    contexte="Intégration de maquette dans le cadre d'un projet personel. Le but de ce projet était de réaliser une page web en reproduidant une maquette ainsi que de m'améliorer en HTML/CSS."
                    technologies="HTML, CSS, JS"
                    screenshot="./img/intégration.png"
                    description="Projet personnel"
                    images="./img/intégration.png,./img/intégration2.png,./img/intégration3.png,./img/intégration4.png"
                    lien="https://pikaadaa.github.io/TP-CSS/"
                    github="https://github.com/Pikaadaa/Integration-Maquette"
                    tagsImages="./img/pngwing.com (11).png, ./img/pngwing.com (10).png, ./img/pngwing.com (13).png"
                />

                <CardProjet
                    title="Iény"
                    contexte="J'ai reproduit un site web qui m'a particulièrement inspiré. L'objectif principal était d'acquérir de l'expérience pratique dans le développement d'un design attrayant."
                    technologies="HTML, CSS, JS"
                    screenshot="./img/intégration.png"
                    description="Projet personnel"
                    images="./img/intégration.png,./img/intégration2.png,./img/intégration3.png,./img/intégration4.png"
                    lien="https://pikaadaa.github.io/TP-CSS/"
                    github="https://github.com/Pikaadaa/Integration-Maquette"
                    tagsImages="./img/pngwing.com (11).png, ./img/pngwing.com (10).png, ./img/pngwing.com (13).png"
                />

                <CardProjet
                    title="Portfolio"
                    description="Projet personnel"
                    technologies="React, CSS"
                    screenshot="./img/portfolio1.png"
                    contexte="Portfolio réalisé en React, j'ai appris en autodidacte à développer en react puis j'ai décidé de me faire une première expérience en développant celui-ci"
                    images="./img/portfolio1.png,./img/portfolio2.png,./img/portfolio3.png,./img/portfolio4.png,./img/portfolio5.png,./img/portfolio6.png"
                    lien="https://www.louisdedrie.fr/"
                    github=""
                    tagsImages="logo512.png, ./img/pngwing.com (10).png"
                />
            </div>
        </div>
    )
}

export default Projets;


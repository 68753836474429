import Skill from "../components/Skill";
import React from "react";

function Competences() {
    return (
        <div id="competences" className="competences" data-aos="fade-up" data-aos-duration="300">
            <h2 className="title">Stack technique</h2>
            <div className="skills">
                <div className="skills_column">
                    <h3>Front-End</h3>
                    <Skill name="React" imageUrl="logo512.png" />
                    <Skill name="VueJS" imageUrl="img/pngwing.com (4).png" />
                    <Skill name="Typescript" imageUrl="img/pngwing.com (9).png" />
                </div>
                <div className="skills_column">
                    <h3>Back-End</h3>
                    <Skill name="NodeJS" imageUrl="img/pngwing.com.png" />
                    <Skill name="Laravel" imageUrl="img/pngwing.com (1).png" />
                    <Skill name="Mysql" imageUrl="img/pngwing.com (2).png" />
                    <Skill name="MongoDB" imageUrl="img/pngwing.com (3).png" />
                </div>
                <div className="skills_column">
                    <h3>Autres</h3>
                    <Skill name="Git & Github" imageUrl="img/icons8-github-64.png" />
                    <Skill name="Vercel" imageUrl="img/pablita-triangle-white.png" />
                </div>
                <div className="skills_column">
                    <h3>Design</h3>
                    <Skill name="Tailwind" imageUrl="img/pngwing.com (7).png" />
                    <Skill name="Figma" imageUrl="img/pngwing.com (8).png" />
                </div>
            </div>
        </div>
    )
}

export default Competences;
import Hobbie from "../components/Hobbie";
import React from "react";

function Hobbies() {
    return (
        <div id="hobbies" className="hobbies" data-aos="fade-up" data-aos-duration="300">
            <h2 className="title">Passions</h2>
            <div className="allHobbies">
                <Hobbie
                    src=".\img\foot2.jpg"
                    title="Football"
                    text="Je fais du football depuis mes 6 ans. C'est un sport que j'adore et qui occupe une grande place dans ma vie. J'ai commencé à jouer dans mon quartier avec mes amis, avant de m'inscrire dans un club de foot. Depuis, je m'entraîne régulièrement et j'ai même participé à des compétitions. Le football m'a appris la détermination, la persévérance et l'esprit d'équipe. C'est un loisir qui me permet de me dépenser physiquement, mais aussi de m'évader mentalement. Je suis passionné de ce sport et j'espère pouvoir continuer à en jouer pendant de nombreuses années encore."
                />
                <Hobbie
                    src=".\img\kcx.png"
                    title="Esport"
                    text="Depuis que j'ai découvert l'esport, je suis devenu un véritable fan. J'adore regarder les matchs et les compétitions en ligne, mais il y a quelque chose de tout à fait exceptionnel à assister à un événement esport en direct. Lorsque je suis dans une salle ou un stade, j'ai l'impression de faire partie de quelque chose de vraiment grand. Le public est si passionné et l'ambiance est tellement électrique ! C'est vraiment une expérience unique et inoubliable. De plus, il y a toujours une telle tension et de l'adrénaline lorsque je regarde un match en direct. C'est vraiment un loisir qui me transporte et qui me fait vibrer. J'espère pouvoir continuer à assister à des événements esport dans le futur."
                />
            </div>
        </div>
    )
}

export default Hobbies;
import React from 'react';

function Hobbie(props) {

    return (
        <div data-aos="fade-up" className="hobbie" data-aos-duration="300">
            <img src={props.src} alt={props.title} />
            <div className='hobbie_content'>
                <h3 className='hobbie_content_title'>{props.title}</h3>
                <p className='hobbie_content_text'>{props.text}</p>
            </div>
        </div>
    )
}

export default Hobbie;